import { useContext } from "react";
import {
  Box,
  ButtonSize,
  ButtonVariant,
  JustifyContent,
  P,
  ButtonLayout,
  TypePreset,
} from "@gocardless/flux-react";
import { GlobalState } from "src/state";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import { Routes } from "src/components/shared/Router";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";

export enum DataTestIds {
  ErrorHeading = "error-heading-bank-auth",
  TryAgainButton = "try-again-button-bank-auth",
}

const ConsentExpired = () => {
  const {
    setError,
    payerTheme,
    setBankAuthorisation,
    setShouldCreateNewBankAuth,
  } = useContext(GlobalState);
  const router = useRouter();

  return (
    <>
      <Box justifyContent={JustifyContent.Center}>
        <P preset={TypePreset.Heading_06} spaceBelow={1}>
          <Trans id="bank-authorisation-error.consent-expired.error-message">
            Your payment agreement has expired
          </Trans>
        </P>
      </Box>
      <>
        <P
          preset={TypePreset.Body_02}
          spaceBelow={2}
          data-testid={DataTestIds.ErrorHeading}
        >
          <Trans id="bank-authorisation-error.consent-expired.restart-message">
            Renew your payment agreement below then authorise from your banking
            app
          </Trans>
        </P>
        <Box spaceBelow={1}>
          <BrandedButton
            backgroundColor={getBrandColorFor(
              BrandedComponentType.Button,
              payerTheme
            )}
            variant={ButtonVariant.PrimaryOnLight}
            onClick={async () => {
              // Clear any bank authorisation we had and to return to BankConfirm.
              await setBankAuthorisation(null);
              // Set shouldCreateNewBankAuth in order to force creating a new bank
              // authorisation
              await setShouldCreateNewBankAuth(true);
              await router.push({
                pathname: Routes.BankConfirm.replace("/", ""),
                query: router.query,
              });
              setError();
            }}
            size={ButtonSize.Lg}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            data-testid={DataTestIds.TryAgainButton}
          >
            <Trans id="bank-authorisation-error.consent-expired.restart-button">
              Renew payment agreement
            </Trans>
          </BrandedButton>
        </Box>
      </>
    </>
  );
};

export default ConsentExpired;
