import { useContext, useEffect } from "react";
import {
  AlignItems,
  Box,
  ButtonSize,
  DL,
  FontWeight,
  P,
  Space,
  TextAlign,
  Text,
  Interpose,
  Button,
  ButtonVariant,
  ButtonLayout,
  Glyph,
  Link,
  DLLayout,
  DT,
  DD,
  ColorPreset,
  H1,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { bankAuthorisationExit } from "@gocardless/api/dashboard/bank-authorisation";
import {
  BrandedComponentType,
  getBankLogoGB,
  getBrandColorFor,
  retryMandateFlow,
} from "src/common/utils";
import { GlobalState } from "src/state";
import { Image } from "src/components/shared/Image";
import BrandedLink from "src/components/shared/BrandedComponents/BrandedLink";
import { REDIRECT_WAIT_PERIOD } from "src/common/consts";
import { Logger } from "src/common/logger";
import { ErrorReasonsEnum } from "src/state/errors";
import { Scheme } from "src/common/scheme";

import ExitBRF from "../../ExitBRF";

const BankAccountMismatchError = () => {
  // Users will be redirected to this error page if
  // the bank details they selected via their institution do not match
  // the bank details they entered during the CollectBankAccount action
  const {
    billingRequest,
    billingRequestFlow,
    bankAuthorisation,
    selectedInstitution,
    payerTheme,
    setError,
  } = useContext(GlobalState);

  const redirectURI = billingRequestFlow?.redirect_uri;
  const exitURI = billingRequestFlow?.exit_uri;
  const merchantURIProvided = redirectURI || exitURI;

  const isACHScheme = billingRequest?.mandate_request?.scheme === Scheme.Ach;

  const isBankAccountCaptured =
    !!billingRequest?.resources?.customer_bank_account;

  const branchCodeEnding =
    billingRequest?.resources?.customer_bank_account?.branch_code_ending;

  const accountNumberEnding =
    billingRequest?.resources?.customer_bank_account?.account_number_ending;

  const merchantEmail =
    billingRequestFlow?.config?.merchant_contact_details?.email;

  const bankLogo = getBankLogoGB(selectedInstitution?.name);

  const canShowTryAgainMessage =
    (isACHScheme && merchantURIProvided) ||
    (!isACHScheme && !isBankAccountCaptured);

  const log = Logger("BankAccountMismatchError");

  useEffect(() => {
    if (isACHScheme) {
      bankAuthorisationExit(bankAuthorisation?.id as string, {
        plaid: {
          exit_reason: ErrorReasonsEnum.BankAccountMismatch,
        },
      }).catch((error) => {
        log({
          error,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (isACHScheme && redirectURI) {
      const timeoutID = setTimeout(() => {
        // Auto redirect to the provided merchant url
        // after the wait period
        window.location.href = redirectURI;
      }, REDIRECT_WAIT_PERIOD);

      // Remove the timeout when leaving the page
      return () => {
        clearTimeout(timeoutID);
      };
    }

    return () => {};
  }, []);

  return (
    <Box layout="flex" flexDirection="column" alignItems={AlignItems.Center}>
      <Box spaceBelow={2}>
        <Image
          name="ExclamationMarkCircle"
          alt="exclamation-mark-circle"
          width={56}
          height={56}
        />
      </Box>
      <H1
        size={4}
        weight={600}
        textAlign={TextAlign.Center}
        spaceBelow={1}
        color={ColorPreset.TextOnLight_01}
      >
        <Trans id="bank-account-mismatch-error.title">
          Your bank account details don&apos;t match the bank account you have
          authorised when signing into your bank
        </Trans>
      </H1>

      {!isACHScheme && isBankAccountCaptured && (
        <>
          <P
            spaceBelow={2}
            textAlign={TextAlign.Center}
            color={ColorPreset.TextOnLight_01}
          >
            <Trans id="bank-account-mismatch-error.instruction-message">
              Please authorise the following account:
            </Trans>
          </P>

          <Box
            borderColor={ColorPreset.BorderOnLight_03}
            borderWidth={1}
            borderRadius={1}
            gutterH={2}
            gutterV={2}
            width={["100%", null, "45%"]}
            spaceBelow={2}
          >
            <Interpose node={<Space v={1.5} />}>
              {bankLogo && (
                <DL layout={DLLayout.Stack}>
                  <DT
                    size={2}
                    color={ColorPreset.TextOnLight_03}
                    weight={FontWeight.Normal}
                  >
                    <Text>
                      <Trans id="bank-account-mismatch-error.bank.label">
                        Bank
                      </Trans>
                    </Text>
                  </DT>
                  <DD size={3} weight={FontWeight.SemiBold}>
                    <Image name={bankLogo} width={80} />
                  </DD>
                </DL>
              )}
              <DL layout={DLLayout.Stack}>
                <DT
                  size={2}
                  color={ColorPreset.TextOnLight_03}
                  weight={FontWeight.Normal}
                >
                  <Text>
                    <Trans id="bank-account-mismatch-error.sort-code.label">
                      Sort code
                    </Trans>
                  </Text>
                </DT>
                <DD size={3} weight={FontWeight.SemiBold}>
                  <Box layout="flex" alignItems={AlignItems.Center}>
                    <Box layout="flex" flexShrink={0}>
                      <Text>
                        <Trans id="bank-account-mismatch-error.branch-code-ending.details">
                          Ending with ****{branchCodeEnding}
                        </Trans>
                      </Text>
                    </Box>
                  </Box>
                </DD>
              </DL>
              <DL layout={DLLayout.Stack}>
                <DT
                  size={2}
                  color={ColorPreset.TextOnLight_03}
                  weight={FontWeight.Normal}
                >
                  <Text>
                    <Trans id="bank-account-mismatch-error.account-number.label">
                      Account number
                    </Trans>
                  </Text>
                </DT>
                <DD size={3} weight={FontWeight.SemiBold}>
                  <Box layout="flex" alignItems={AlignItems.Center}>
                    <Box layout="flex" flexShrink={0}>
                      <Text>
                        <Trans id="bank-account-mismatch-error.account-number-ending.details">
                          Ending with ******{accountNumberEnding}
                        </Trans>
                      </Text>
                    </Box>
                  </Box>
                </DD>
              </DL>
            </Interpose>
          </Box>
        </>
      )}

      {canShowTryAgainMessage && (
        <>
          <P textAlign={TextAlign.Center} spaceBelow={2}>
            <Trans id="bank-account-mismatch-error.try-again-message">
              Please try again and choose the same bank account you provided
              when you sign in to your bank to authorise the payment.
            </Trans>
          </P>
        </>
      )}

      {isACHScheme && merchantURIProvided && (
        <Box spaceBelow={0.75}>
          <Link
            variant={ButtonVariant.PrimaryOnLight}
            href={(exitURI || redirectURI) as string}
            size={ButtonSize.Lg}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            rightIcon={Glyph.ArrowForward}
          >
            <Trans id="Try again">Try again</Trans>
          </Link>
        </Box>
      )}

      {!isACHScheme && (
        <Box spaceBelow={1}>
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            onClick={() => retryMandateFlow(billingRequest)}
            size={ButtonSize.Lg}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            rightIcon={Glyph.ArrowForward}
          >
            <Trans id="Try again">Try again</Trans>
          </Button>
        </Box>
      )}

      {!isACHScheme && (
        <Box spaceBelow={0.75}>
          <ExitBRF
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            payerTheme={payerTheme}
            setError={setError}
            fontWeight={FontWeight.SemiBold}
            buttonSize={ButtonSize.Md}
          />
        </Box>
      )}

      {isACHScheme && !merchantURIProvided && merchantEmail && (
        <P size={3} textAlign={TextAlign.Center} spaceBelow={0.75}>
          <Trans id="bank-account-mismatch-error.contact-support-message">
            Please contact{" "}
            <BrandedLink
              textColor={getBrandColorFor(
                BrandedComponentType.Link,
                payerTheme
              )}
              href={`mailto:${merchantEmail}`}
              variant={ButtonVariant.Inline}
            >
              {merchantEmail}
            </BrandedLink>{" "}
            if you need any help.
          </Trans>
        </P>
      )}

      {!isACHScheme && !merchantURIProvided && merchantEmail && (
        <P
          size={2}
          textAlign={TextAlign.Center}
          weight={FontWeight.SemiBold}
          color={ColorPreset.TextOnLight_01}
        >
          <BrandedLink
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            href={`mailto:${merchantEmail}`}
            variant={ButtonVariant.Inline}
          >
            <Trans id="bank-account-mismatch-error.email-link.title">
              Email {billingRequest?.creditor_name}
            </Trans>
          </BrandedLink>
        </P>
      )}
    </Box>
  );
};

export default BankAccountMismatchError;
