import { useContext } from "react";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";
import {
  AlignItems,
  Box,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Color,
  Glyph,
  Icon,
  JustifyItems,
  P,
  TextAlign,
  TypePreset,
  XYGrid,
} from "@gocardless/flux-react";
import { ErrorType, GlobalState } from "src/state";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import { BankIdErrorsEnum } from "src/state/errors";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import ExitBRF from "src/components/shared/ExitBRF";

const BankIdVerificationUnsuccessful = ({
  errorMessage,
}: {
  errorMessage?: ErrorType["errorMessage"];
}) => {
  const {
    payerTheme,
    billingRequest,
    billingRequestFlow,
    setError,
    setBankAuthorisation,
  } = useContext(GlobalState);
  const router = useRouter();
  const timeLimitExceeded = errorMessage === BankIdErrorsEnum.TimeLimitExceeded;

  return (
    <XYGrid justifyItems={JustifyItems.Center} rowGap={3}>
      <Icon
        name={Glyph.ExclamationCircle}
        size="60px"
        color={Color.Warning_300}
      />
      <Box
        maxWidth={400}
        layout="flex"
        flexDirection="column"
        alignItems={AlignItems.Center}
      >
        <P
          preset={TypePreset.Heading_03}
          textAlign={TextAlign.Center}
          spaceBelow={1.5}
        >
          <Trans id="bank-id-verification.unsuccessful-message">
            BankID verification unsuccessful
          </Trans>
          {timeLimitExceeded && (
            <span>
              <br />
              <Trans id="bank-id-verification.time-limit-exceeded">
                Time limit exceeded
              </Trans>
            </span>
          )}
        </P>

        <P preset={TypePreset.Body_02} textAlign={TextAlign.Center}>
          {timeLimitExceeded ? (
            <Trans id="bank-id-verification.time-limit-exceeded-instruction">
              Click the button below to begin the process again. You will have a
              limited amount of time, so please sign into your app immediately.
            </Trans>
          ) : (
            <Trans id="bank-id-verification.try-another-bank-message">
              We haven&apos;t been able to verify your BankID. Please begin the
              process again.
            </Trans>
          )}
        </P>
      </Box>

      <BrandedButton
        type="submit"
        backgroundColor={getBrandColorFor(
          BrandedComponentType.Button,
          payerTheme
        )}
        onClick={() => {
          setBankAuthorisation(null, () => {
            setError(undefined);

            router.push({
              pathname: "autogiro-bankid-connect",
              query: router.query,
            });
          });
        }}
        size={ButtonSize.Lg}
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, ButtonLayout.Inline]}
        rightIcon={Glyph.ArrowForward}
      >
        <Trans id="autogiro-bankid.error.try-again">Try again</Trans>
      </BrandedButton>

      {billingRequestFlow?.exit_uri && (
        <ExitBRF
          billingRequestFlow={billingRequestFlow}
          billingRequest={billingRequest}
          setError={setError}
          payerTheme={payerTheme}
        />
      )}
    </XYGrid>
  );
};

export default BankIdVerificationUnsuccessful;
