import { useContext } from "react";
import {
  AlignItems,
  Box,
  Button,
  ButtonLayout,
  ButtonVariant,
  ButtonSize,
  FontWeight,
  Glyph,
  H1,
  P,
  Space,
  TextAlign,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Image } from "src/components/shared/Image";
import { GlobalState } from "src/state";
import { retryMandateFlow } from "src/common/utils";

import ExitBRF from "../../ExitBRF";

const InvalidBankAccountsError = () => {
  const { billingRequest, billingRequestFlow, payerTheme, setError } =
    useContext(GlobalState);

  return (
    <Box layout="flex" flexDirection="column" alignItems={AlignItems.Center}>
      <Box spaceBelow={2}>
        <Image
          name="ExclamationMarkCircle"
          alt="Exclamation mark icon"
          width={60}
          height={60}
        />
      </Box>
      <H1 size={4} weight={600} textAlign={TextAlign.Center} spaceBelow={1}>
        <Trans id="invalid-bank-accounts-error.title">
          The bank account or accounts selected aren&apos;t supported
        </Trans>
      </H1>
      <P size={3} textAlign={TextAlign.Center} spaceBelow={2}>
        <Trans id="invalid-bank-accounts-error.message">
          Your Direct Debit couldn&apos;t be set up because the bank account or
          accounts selected aren&apos;t supported.
        </Trans>
      </P>
      <Box>
        <Button
          variant={ButtonVariant.PrimaryOnLight}
          layout={[ButtonLayout.Full, ButtonLayout.Inline]}
          onClick={() => retryMandateFlow(billingRequest)}
          size={[ButtonSize.Md, ButtonSize.Lg]}
          rightIcon={Glyph.ArrowForward}
        >
          <Trans id="invalid-bank-accounts-error.try-again-button.title">
            Use another bank account
          </Trans>
        </Button>
      </Box>
      <Space v={1} />
      <ExitBRF
        billingRequest={billingRequest}
        billingRequestFlow={billingRequestFlow}
        payerTheme={payerTheme}
        setError={setError}
        fontWeight={FontWeight.SemiBold}
      />
    </Box>
  );
};

export default InvalidBankAccountsError;
