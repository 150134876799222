import { Fragment, useContext } from "react";
import { ErrorType, GlobalState } from "src/state";
import { ErrorsEnum, PlaidErrorsEnum } from "src/state/errors";
import { captureException } from "src/common/sentry";
import BankAccountMismatchError from "src/components/shared/ErrorsBoundary/BankAccountMismatchError";
import BankAuthAlreadyCompleted from "src/components/pages/BankPlaidLink/errors/BankAuthAlreadyCompleted";

import Layout from "../Layout";

import BankIdAppNotFound from "./BankIdErrors/AppNotFound";
import LinkExpiredError from "./LinkExpiredError";
import LinkNotWorkingError from "./LinkNotWorkingError";
import RestartError from "./RestartError";
import GenericError from "./GenericError";
import SessionExpiredError from "./SessionExpiredError";
import {
  BankAuthorisationError,
  BankAuthorisationConsentExpiredError,
} from "./BankAuthorisationErrors";
import PayerCountryNotSupportedError from "./PayerCountryNotSupported";
import InstitutionLookupError from "./InstitutionLookupError";
import InvalidBankAccountsError from "./InvalidBankAccountsError";
import BankIdVerificationUnsuccessful from "./BankIdErrors/VerificationUnsuccessful";
import BankAccountNotEligibleError from "./BankAccountNotEligibleError";

// Global state has an error property that is set whenever something goes wrong.
// The ErrorsBoundary watches for an error and renders an error modal if it
// becomes non-null.
const ErrorsBoundary = ({ children }: { children: React.ReactNode }) => {
  const { error } = useContext(GlobalState);

  if (error) {
    return <ErrorsBoundaryView error={error} />;
  }

  // Otherwise render the child elements
  return <>{children}</>;
};

const errorsToIgnoreInSentry = [
  ErrorsEnum.LinkExpired,
  ErrorsEnum.SessionExpired,
  ErrorsEnum.BankAccountMismatch,
  ErrorsEnum.BankAuthorisationAlreadyCompleted,
  ErrorsEnum.BankAuthorisationError,
  ErrorsEnum.NoValidBankAccount,
  ErrorsEnum.PayerCountryNotSupported,
  ErrorsEnum.BankIdVerificationUnsuccessful,
  ...Object.values(PlaidErrorsEnum),
];

export const ErrorsBoundaryView = ({ error }: { error?: ErrorType }) => {
  // Fire off an event to sentry - ignore certain types
  if (error && !errorsToIgnoreInSentry.includes(error.errorType)) {
    captureException(
      Error(`Error state occured: ${error.errorType} - ${error.errorMessage}`),
      { httpError: error.httpError }
    );
  }

  switch (error?.errorType) {
    case ErrorsEnum.LinkExpired:
      return <LinkExpiredError />;
    case ErrorsEnum.PaymentRequestError:
    case ErrorsEnum.InstitutionError:
    case ErrorsEnum.ApiError:
      return <RestartError />;
    case ErrorsEnum.BankAuthorisationError:
      return <BankAuthorisationError />;
    case ErrorsEnum.BankAuthConsentExpired:
      return <BankAuthorisationConsentExpiredError />;
    case ErrorsEnum.SessionExpired:
      return <SessionExpiredError />;
    case ErrorsEnum.BankAccountNotEligibleForScheme:
      return <BankAccountNotEligibleError />;
    case ErrorsEnum.BankAccountMismatch: {
      // Conditionally wrap the component in <Layout />,
      // depending on where error is triggered from.
      // TODO: Look for a better workaround for this
      const Wrapper: React.ElementType = error.httpError ? Layout : Fragment;

      return (
        <Wrapper>
          <BankAccountMismatchError />
        </Wrapper>
      );
    }
    case ErrorsEnum.BankAuthorisationAlreadyCompleted:
      return <BankAuthAlreadyCompleted />;
    case ErrorsEnum.NoValidBankAccount:
      return <InvalidBankAccountsError />;
    case ErrorsEnum.PayerCountryNotSupported:
      return <PayerCountryNotSupportedError />;
    case ErrorsEnum.InstitutionLookupError:
      return <InstitutionLookupError />;
    case ErrorsEnum.BankIdVerificationUnsuccessful:
      return (
        <BankIdVerificationUnsuccessful errorMessage={error.errorMessage} />
      );
    case ErrorsEnum.BankIdAppNotFound:
      return <BankIdAppNotFound />;
    case ErrorsEnum.ResumeUnauthorisedError:
      return <LinkNotWorkingError />;
    default:
      return <GenericError />;
  }
};

export default ErrorsBoundary;
