import { useContext } from "react";
import {
  Box,
  ButtonLayout,
  ButtonVariant,
  ButtonSize,
  FontWeight,
  P,
  Space,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { GlobalState } from "src/state";
import {
  BrandedComponentType,
  getBrandColorFor,
  isEligibleForFallback,
  retryMandateFlow,
  retryPaymentFlow,
  usesOpenBankingGatewayAisAdapter,
} from "src/common/utils";
import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import { GC_SUPPORT_EMAIL } from "src/common/consts";
import { TrackingEvents } from "src/common/trackingEvents";

import BrandedButton from "../../BrandedComponents/BrandedButton";
import BrandedLink from "../../BrandedComponents/BrandedLink";
import ExitBRF from "../../ExitBRF";
import FallbackButton from "../../FallbackButton";

const RestartError = ({ message }: { message?: string }) => {
  const {
    billingRequest,
    billingRequestFlow,
    bankAuthorisation,
    payerTheme,
    setError,
    setBankAuthorisation,
  } = useContext(GlobalState);

  const supportEmail =
    billingRequestFlow?.config?.merchant_contact_details?.email;
  const supportEmailHref = `mailto:${supportEmail ?? GC_SUPPORT_EMAIL}`;

  return (
    <Box>
      <P size={6} weight={FontWeight.SemiBold}>
        {message ?? (
          <Trans id="restart-error.error-message">
            It looks like something has gone wrong
          </Trans>
        )}
      </P>
      <Space v={1} />
      {usesOpenBankingGatewayAisAdapter(
        billingRequest as BillingRequestResource
      ) && (
        <>
          <P size={3} spaceBelow={2}>
            <Trans id="restart-error.ais.contact-support-message">
              Please start again, or contact our{" "}
              <BrandedLink
                textColor={getBrandColorFor(
                  BrandedComponentType.Link,
                  payerTheme
                )}
                href={supportEmailHref}
                variant={ButtonVariant.InlineUnderlined}
              >
                support team
              </BrandedLink>{" "}
              if you need help.
            </Trans>
          </P>
          <Box spaceBelow={1}>
            <BrandedButton
              backgroundColor={getBrandColorFor(
                BrandedComponentType.Button,
                payerTheme
              )}
              variant={ButtonVariant.PrimaryOnLight}
              onClick={async () => {
                await retryMandateFlow(billingRequest);
                setBankAuthorisation(null, () => {
                  setError(undefined);
                });
              }}
              size={ButtonSize.Lg}
              layout={[ButtonLayout.Full, ButtonLayout.Inline]}
            >
              <Trans id="Try again">Try again</Trans>
            </BrandedButton>
          </Box>
          <ExitBRF
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            payerTheme={payerTheme}
            setError={setError}
          />
        </>
      )}

      {!usesOpenBankingGatewayAisAdapter(
        billingRequest as BillingRequestResource
      ) && (
        <>
          <P size={3}>
            <Trans id="restart-error.contact-support-message">
              Please try starting the payment process again, or contact our{" "}
              <BrandedLink
                textColor={getBrandColorFor(
                  BrandedComponentType.Link,
                  payerTheme
                )}
                href={supportEmailHref}
                variant={ButtonVariant.InlineUnderlined}
              >
                support team
              </BrandedLink>{" "}
              if you need help.
            </Trans>
          </P>
          <Space v={1.5} />
          <BrandedButton
            backgroundColor={getBrandColorFor(
              BrandedComponentType.Button,
              payerTheme
            )}
            variant={ButtonVariant.PrimaryOnLight}
            onClick={async () => {
              await retryPaymentFlow(billingRequest);
              setBankAuthorisation(null, () => {
                setError(undefined);
              });
            }}
            size={ButtonSize.Lg}
            layout={[ButtonLayout.Full, ButtonLayout.Inline]}
          >
            <Trans id="restart-error.restart-button">
              Restart payment process
            </Trans>
          </BrandedButton>
          {isEligibleForFallback(billingRequest, bankAuthorisation) ? (
            <Box spaceAbove={1.5}>
              <FallbackButton
                event={TrackingEvents.BANK_AUTHORISATION_STEP_FALLBACK_CLICKED}
                variant={ButtonVariant.Inline}
                buttonSize={ButtonSize.Md}
              />
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default RestartError;
